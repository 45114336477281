jQuery(document).ready(function ($) {
    // console.log('app.js loaded');

    $('.hamburger').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('nav-close, is-active');
        $('body').toggleClass('nav-open');

        $('.header-main-nav li.current-menu-parent').delay(600).queue(function () {
         //   $(this).children('div').addClass('menu-item-has-children--arrow--active');
             $(this).find('.sub-menu-wrap').first().stop(true, true).slideDown('medium');
              });

    });

    if ($('.scroll-link').length) {
        $('.scroll-link a').on('click', function (e) {
            e.preventDefault();

            var target = $(this).attr('href');
            $('html, body').animate({
                'scrollTop': $(target).offset().top - 50
            }, 1000);

        });
    }    

    // if ($(window).width() < 992) {
    //     jQuery('.sh__content .sub-menu').prepend('<li class="menu-back"><a href="#"><b>< Back</b></a></li>');
    // }

    jQuery('.header-main-nav .menu-item-has-children').prepend('<span><i class="far fa-angle-right"></i></span>');

    $('.menu-item-has-children span').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('menu-open');
        $(this).siblings('.sub-menu-wrap').slideToggle();
        $(this).siblings('.sub-sub-menu').slideToggle();
    });

    /* $('.menu-item-has-children > a').on('click', function (e) {
         e.preventDefault();
         $(this).siblings('.sub-menu').addClass('in');
     }); 

    $('.back a').on('click', function (e) {
        e.preventDefault();
        $(this).closest('.sub-menu').removeClass('in');
    });

    $('.menu-back').on('click', function (e) {
        e.preventDefault();
        $(this).closest('.sub-menu').removeClass('in');
    }); */

    if ($(window).width() > 767) {
        var $sticky = jQuery('.sticky');
        var $stickyrStopper = jQuery('footer');

        if (!!$sticky.offset()) { // make sure ".sticky" element exists

            var generalSidebarHeight = $sticky.innerHeight();
            var stickyTop = $sticky.offset().top;
            var stickOffset = 70;
            var stickyStopperPosition = $stickyrStopper.offset().top;
            var stopPoint = stickyStopperPosition - generalSidebarHeight - stickOffset;
            var diff = stopPoint + stickOffset;

            $(window).scroll(function () { // scroll event
                var windowTop = $(window).scrollTop(); // returns number
                console.log('windowTop is ' + windowTop);
                console.log('stopPoint is ' + stopPoint);

                if (stopPoint < windowTop) {
                    $sticky.css({position: 'sticky', top: '20px', height: '100%'});
                }
            });

        }
    }


    // Smooth anchor scroll
    jQuery('a[href*="#"]:not([href="#"])').click(function () {
        var offset = -200; // <-- change the value here
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top + offset
                }, 1000);
                return false;
            }
        }
    });


    jQuery('.block-title svg').waypoint(function(direction) {
        if (direction == 'down')
        {
            jQuery(this.element).addClass('slide-right');
        }
    }, { offset: '80%' }); // distance from top


    jQuery('.gallery-images').waypoint(function(direction) {
        if (direction == 'down')
        {
            jQuery(this.element).addClass('gallery-images--show');
        }
    }, { offset: '60%' }); // distance from top


    /* jQuery( '.feature-sub-nav' ).waypoint( function( direction ) {
        var classes = 'feature-sub-nav--fixed';
        if( jQuery( '.sh__banner' ).length ) {
            classes += ' feature-sub-nav--fixed-with-site-banner';
        }
        
        if( direction === 'down' ) {
            jQuery( this.element ).addClass( classes );
        } else {
            jQuery( this.element ).removeClass( classes );
        }
    }, { offset: '55px' } ); */

    jQuery( '.feature-header' ).waypoint( function( direction ) {

        if( direction === 'down' ) {
            jQuery( '.feature-sub-nav' ).addClass( 'feature-sub-nav--fixed' );
        } else {
            jQuery( '.feature-sub-nav' ).removeClass( 'feature-sub-nav--fixed' );
        }
    }, { offset: '-35%' } );



    jQuery('.notice-feature-sub-nav-enable .feature-sub-nav').waypoint(function(direction) {
        if (direction == 'down')
        {
            jQuery(this.element).addClass('feature-sub-nav--fixed');
        }
        else {
            jQuery(this.element).removeClass('feature-sub-nav--fixed');
        }
    }, { offset: '120px' }); // distance from top

    jQuery('.post-listing-container .post-listing-grid article').waypoint(function(direction) {
        if (direction == 'down')
        {
            jQuery(this.element).addClass('article--show');
        }
    }, { offset: '80%' }); // distance from top

    jQuery(window).on( 'scroll', function() {
        if ($(window).scrollTop() > 200) {
            // console.log('scrolled'); // For some reason, when compiling the following function didn't work unless there was something here! - JF
                jQuery('.sh__content__logo').addClass('logo-shrink');
                jQuery('.height-block').addClass('scrolled');
            } else {
            jQuery('.sh__content__logo').removeClass('logo-shrink');
            jQuery('.height-block').removeClass('scrolled');
        }
    });

    // Check gallery is this ok here?
    // jQuery(document).ready(function () {

    // Ajax gallery
        // var mySwiper = new Swiper('#ajax-target-<?php echo $gallery_block_id; ?> .swiper-container', {
        //     effect: 'fade',
        //     navigation: {
        //         nextEl: '.gallery-next',
        //         prevEl: '.gallery-prev',
        //     },
        //     loop: true,
        // });
    // });


    // Collapsable module

    $('.collapsible-block-button').click(function() {
        var clicks = $(this).data('clicks');
        $(".collapsible-block-toggle button").removeClass('collapsible-button-active');

        if (clicks) { // odd clicks
            $(this).attr("aria-expanded","false");
            $(this).parent().removeClass('collapsible-open');
            $(this).next(".collapsible-block--items-items-content").slideUp().attr("aria-hidden","true");
        } else { // even clicks
            $(".collapsible-block-toggle button").removeClass('collapsible-button-active');
            $(this).attr("aria-expanded","true");
            $(this).parent().addClass('collapsible-open');
            $(this).next(".collapsible-block--items-items-content").slideDown().attr("aria-hidden","false");
        }
        $(this).data("clicks", !clicks);
    });

    $(".collapsible-block-toggle--open").click(function() {

        $(this).addClass('collapsible-button-active');
        $(".collapsible-block-toggle--close").removeClass('collapsible-button-active');

        $(this).parent().next('.collapsible-block--items').children().addClass('collapsible-open');

        $(this).parent().next('.collapsible-block--items').children().find('button').attr("aria-expanded","true");
        $(this).parent().next('.collapsible-block--items').children().find('.collapsible-block--items-items-content').slideDown().attr("aria-hidden","false");
    });
    $(".collapsible-block-toggle--close").click(function() {

        $(this).addClass('collapsible-button-active');
        $(".collapsible-block-toggle--open").removeClass('collapsible-button-active');

        $(this).parent().next('.collapsible-block--items').children().removeClass('collapsible-open');
        $(this).parent().next('.collapsible-block--items').children().find('button').attr("aria-expanded","false");
        $(this).parent().next('.collapsible-block--items').children().find('.collapsible-block--items-items-content').slideUp().attr("aria-hidden","true");
    });

    // Gallery module

    

    var mySwiper = new Swiper('.swiper-gallery', {
        effect: 'fade',
        navigation: {
            nextEl: '.gallery-next',
            prevEl: '.gallery-prev',
        },
        loop: true,
    });

    var Gridswiper = new Swiper('.grid-carousel .swiper', {
        slidesPerView: 1,
        spaceBetween: 40,
        loop: true,
        speed: 1000,
        watchOverflow: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
         navigation: {
            nextEl: ".grid-button-next",
            prevEl: ".grid-button-prev",
        },
        breakpoints: {
            565: {
             slidesPerView: 2,
             spaceBetween:40,
            },
            993: {
                slidesPerView: 3,
                spaceBetween:40,
               },
          },
    });


    // Case Studies Filters
    
    // Reset
    $('.cs-filter-reset').on('click', function (e) {
        e.preventDefault();

        if($(this).hasClass('active')){
            

        } else {

            $(this).toggleClass('active');
            $('.cs-filter-trigger').removeClass('active');
            
            // Show All
            $('.cs-grid article').removeClass('on off');

        }
        

    });
    
    // Hide All then Show Selected
    $('.cs-filter-trigger').on('click', function (e) {
        e.preventDefault();

        catID = $(this).attr('data-id');

        if($(this).hasClass('active')){

            //$(this).removeClass('active');
            // Reset all Triggers
            $('.cs-filter-trigger').removeClass('active');

            // Show All
            $('.cs-grid article').removeClass('on off');

            // Set Reset Button On
            $('.cs-filter-reset').addClass('active');

        } else {

            // Reset all Triggers
            $('.cs-filter-trigger').removeClass('active');
            // Set this Trigger On
            $(this).addClass('active');
            
            // Hide All
            //$('.cs-grid article:not(.on)').addClass('off');
            $('.cs-grid article').removeClass('on');
            $('.cs-grid article').addClass('off');
            
            // Show Selected
            $('.cs-grid article.mc_casestudies_cats-' + catID).removeClass('off');
            $('.cs-grid article.mc_casestudies_cats-' + catID).addClass('on');

            // Set Reset Button Off
            $('.cs-filter-reset').removeClass('active');

        }
        
    });

});

jQuery(document).ready(function ($) {
    var swiper = new Swiper('.hero-container', {
        slidesPerView: 1,
        speed: 2000,
        pauseOnMouseEnter: false,
        disableOnInteraction: true,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        effect: 'fade',
    });
});